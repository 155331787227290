// Custom keyframe animations

@include keyframes(slideIn) {
  0%  { 
    transform: translateY(0rem); 
    opacity: 0;
  }
  100% { 
    transform: translateY(1rem);
    opacity: 1;
  }
  0% { 
    transform: translateY(0rem);
    opacity: 0;
  }
}

@include keyframes(arrowBounce) {
  0%  { transform: rotate(-45deg) translate(0, 0); }
  20% { transform: rotate(-45deg) translate(-10px, 10px); }
  40% { transform: rotate(-45deg) translate(0, 0); }
}