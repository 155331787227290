.break-out{
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
}

a {
  color: #9c802a;
  &:hover {
    color: #cca43a;
  }
}

.page-hero {
  background-color: none;
  border-radius: 0;
  position: relative;
  padding-top: 300px;
  background-position: 20% center;
  background-size: cover;
  min-height: 60vh;
  h1, h2 {
    color: #fff;
    text-shadow: 0 2px 3px #222;
    z-index: 10;
  }
  &::after {
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 80%;
    background: -webkit-linear-gradient(top,rgba(0,0,0,0) 0,rgba(0,0,0,.5) 80%,rgba(0,0,0,.8) 100%);
    background: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,.5) 80%,rgba(0,0,0,.8) 100%);
  }
}
.home-hero {
  background-image: url("home-hero.jpg");
  height: 100vh;
  &::after {
    background: -webkit-linear-gradient(top,rgba(0,0,0,0) 0,rgba(0,0,0,0) 80%,rgba(0,0,0,.8) 100%);
    background: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,0) 80%,rgba(0,0,0,.8) 100%);
  }
}
.inner-hero {
  background-image: url("product-hero.jpg");
  background-position: center 10%;
}
.hero-applications {
  background-image: url("hero-applications.jpg");
  background-position: center 20%;
}
.hero-products {
  background-image: url("hero-products.jpg");
  background-position: center 20%;
}
.hero-store {
  background-image: url("hero-store.jpg");
  background-position: center 20%;
}
.hero-blog {
  background-image: url("hero-blog.jpg");
  background-position: center 50%;
}
.hero-about {
  background-image: url("hero-about.jpg");
  background-position: center 70%;
}
.hero-contact {
  background-image: url("hero-contact.jpg");
  background-position: center 70%;
}
.hero-resources {
  background-image: url("hero-resources.jpg");
  background-position: center 50%;
}
.scroll-arrow {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  @include translate(0, -50%);
  color: #fff;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
  padding-top: 60px;
  span {
    &:hover {
      opacity: 0.5;
    }
    position: absolute;
    top: 0;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-left: -15px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    @include transform(rotate(-45deg));
    box-sizing: border-box;
    @include animation(arrowBounce 2s infinite);
  }
}

@media (min-width: 992px) {
  .menu-slide {
    @include animation(slideIn 0.3s both);
  }
}

.navbar-color {
  background-color: $nav-color-bg;
}
@media (max-width: 991px) {
  .navbar {
    background-color: $nav-color-bg !important;
  }
}
.navbar {
  .navbar-nav {
    .nav-link {
      border-bottom: 2px solid transparent;
      text-transform: uppercase;
      letter-spacing: 1px;
      &:hover,
      &:focus {
        border-bottom: 2px solid $theme-gold-light;
        color: $theme-gold-light;
      }
    }
  }
  .dropdown-item {
    text-transform: uppercase;
  }
}

.logo-icon {
  width: 150px;
  display: inline-block;
}

.features {
  background: $gray-200;
}

/*
* Styles targeting pages by their containerId
*/

#home {
  .home-products {
    .product-box {
      background-size: cover;
      background-position: center;
      height: 200px;
    }
  }
}

#applications {
  img {
    width: 100%;
  }
}

.resources-grid {
  .svg-inline--fa {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

#products {
  .product-wrap {
    position: relative;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid $gray-300;
    .anchor {
      position: absolute;
      left: 0px;
      top: -119px;
    }
  }
}

#store {
  .sidebar-col {
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    // background-color: #f8f9fa;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }
  .category-list {
    position: sticky;
    top: 0;
    // padding: 0 15px;
    ul {
      list-style-type: none;
      padding: 0;
      // li {
      //   border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      // }
    }
  }
  .sticky-offset {
    top: 120px;
  }
  .category-anchor {
    position: relative;
    a {
      position: absolute;
      left: 0px;
      top: -119px;
    }
  }
  .list-group {
    width: 100%;
    .list-group-item {
      border-radius: 0;
      border-left: none;
      border-right: none;
      .media {
        min-height: 150px;
      }
    }
  }
}

#blog {
  article {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  article + article {
    border-top: none;
  }
}

#contact {
  .svg-inline--fa {
    margin-right: 5px;
  }
}



/* ======================== */

.main-search-box {
  max-width: 600px;
}

.search-form {
  position: relative;
  width: 100%;
  .search-input {
    font-size: 0.875rem;
    @include border-radius(1.5rem);
    padding-right: 3rem;
    padding-left: 1.5rem;
    &:focus {
      border-color: $theme-divider;
    }
  }
  .search-btn {
    color: lighten($theme-text-color-secondary, 15%);
    background: none;
    border: none;
    position: absolute;
    right: 0;
    top: 0.15rem;
    margin-right: 0;
    &:active, &:focus, &:hover {
      outline: none !important;
      color: $theme-color-primary;
      @include box-shadow(none);
    }
  }
}

.docs-logo-wrapper {
  // position: absolute;
  // left: 15px;
  // top: 0.5rem;
  .site-logo {
    display: inline-block;
  }
}

.site-wrapper {
  position: relative;
}

.callout-block {
  padding: 1.5rem;
  border-left: 3px solid $theme-text-color-secondary;
  background: $smoky-white;
  margin-top: 1rem;
  margin-bottom: 1rem;
  code {
    background: #fff;
  }
  .callout-title {
    font-size: 1rem;
  }
	
  &.callout-block-info {
    border-color: $theme-info-color;
    background: lighten($theme-info-color, 35%);
    .callout-title {
      color: darken($theme-info-color, 15%);
    }
    a {
      color: darken($theme-info-color, 15%);
    }
  }
  &.callout-block-success {
    border-color: $theme-success-color;
    background: lighten($theme-success-color, 40%);
    .callout-title {
      color: darken($theme-success-color, 15%);
    }
    a {
      color: darken($theme-success-color, 15%);
    }
  }
  &.callout-block-warning {
    border-color: $theme-warning-color;
    background: lighten($theme-warning-color, 35%);
    .callout-title {
      color: darken($theme-warning-color, 15%);
    }
    a {
      color: darken($theme-warning-color, 15%);
    }
  }
  &.callout-block-danger {
    border-color: $theme-danger-color;
    background: lighten($theme-danger-color, 35%);
    .callout-title {
      color: darken($theme-danger-color, 15%);
    }
    a {
      color: darken($theme-danger-color, 15%);
    }
  }
}

.cta-section {
  .container {
    position: relative;
    z-index: 10;
  }
  .section-intro {
    font-size: 1.125rem;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
	
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Small devices (landscape phones, less than 768px)
// @media (max-width: 767.98px) { 
//   .docs-article .docs-heading {
//     font-size: 2.25rem;
//   }
//   .docs-article .section-heading {
//     font-size: 1.5rem;
//   }
// }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 

}

// Large devices (desktops, less than 1200px)
// @media (max-width: 1199.98px) { 
//   .docs-content {
//     margin-left: 0;
//   } 
//   .docs-article .docs-time {
//     position: static;
//     display: block;
//     padding-top: 0.5rem;
//   }
// }

// Extra large devices (large desktops)
// No media query since the extra-large breakpoint has no upper bound on its width
