// Override Bootstraps Variables Here
// Reference node_modules/bootstrap/scss/_variables.scss for a full list of variables
// https://getbootstrap.com/docs/4.3/getting-started/theming/#variable-defaults

// $theme-color-primary: #28b76b;
$theme-color-primary: #44565f;

$nav-color-bg: #15233d;
$theme-gold-light: #cca43a;
$theme-gold-dark: #9c802a;

$theme-text-color-primary: #252930;
$theme-text-color-secondary: lighten($theme-text-color-primary, 25%);
$theme-text-color-light: lighten($theme-text-color-primary, 40%);

$theme-bg-light: #f4fcf6;
$theme-bg-dark: $theme-color-primary;
$theme-bg-color: $theme-color-primary;

$theme-border-color:  #efefef;
$theme-divider-color: #efefef;

$theme-divider: lighten($theme-text-color-primary, 75%);

$theme-color-new: #5ab66e;
$theme-error: #EC575B;

$smoky-white: #f5f5f5;
$google-color: #d73d32;
$facebook-color: #3b5998;
$twitter-color: #1DA1F2;
$github-color: #24282D;
$producthunt-color: #da552f;
$wordpress-color: #028cb0;

$single-col-max: 800px;

$theme-success-color: #5cb377;
$theme-warning-color: #EEBF41;
$theme-info-color: #5b99ea;
$theme-danger-color: #d26d69;

$gray-100: lighten($theme-text-color-secondary, 65%);
$gray-200: lighten($theme-text-color-secondary, 55%);
$gray-300: lighten($theme-text-color-secondary, 50%);
$gray-400: lighten($theme-text-color-secondary, 45%);
$gray-500: lighten($theme-text-color-secondary, 40%);
$gray-600: lighten($theme-text-color-secondary, 30%);
$gray-700: lighten($theme-text-color-secondary, 20%);
$gray-800: lighten($theme-text-color-secondary, 10%);
$gray-900: $theme-text-color-primary;

$theme-colors: (
  "primary":  $theme-color-primary, 
  "secondary": $theme-text-color-secondary, 
  "success": $theme-success-color,
  "danger": $theme-danger-color,
  "warning": $theme-warning-color,
  "info": $theme-info-color,
);
